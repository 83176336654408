// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Container from '@mui/material/Container';

export default function ContainerSecBG(props) {
    // TODO: Return component
    return (
        <Container sx={{
            backgroundColor: 'primary.main',
            borderRadius: 5,
        }}>
            {props.children}
        </Container>
    );
}