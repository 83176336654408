// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import WofooSocialEnterprises from '../../Partnership/Logos/WofooSocialEnterprises.jpg';
//import WofooSocialEnterprises_Intro from './WofooSocialEnterprises_Intro.jpg';
import WofooSocialEnterprises_Video1 from './WofooSocialEnterprises_Video1.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import TypographyContents from '../../common/TypographyContents';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'WofooFoundation'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={WofooSocialEnterprises} sx={{ height: '50px' }} />
                {Text.CaseSharing.WofooFoundation.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContentTitle>
                    {Text.CaseSharing.WofooFoundation.Intro.Title[context.language]}
                </TypographyContentTitle>
                <TypographyContents contents={Text.CaseSharing.WofooFoundation.Intro.Content[context.language]} />
                <Box sx={{ maxWidth: '600px', position: 'relative', }}>
                    <Box
                        component="img"
                        src={WofooSocialEnterprises_Video1}
                        sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                    />
                    <DialogVideo url={Text.CaseSharing.WofooFoundation.Video[0].URL} />
                </Box>
                {/*
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Stack>
                            <TypographyContentTitle>
                                {Text.CaseSharing.WofooSocialEnterprises.VideoProduction.Title[context.language]}
                            </TypographyContentTitle>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: Text.CaseSharing.WofooSocialEnterprises.VideoProduction.Content[context.language]
                                }}
                                variant="body"
                            />
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            src={WofooSocialEnterprises_Intro}
                            sx={{
                                borderRadius: 5,
                                objectFit: 'contain',
                                width: 1,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: 'center' }}
                >
                    {
                        Text.CaseSharing.WofooSocialEnterprises.VideoProduction.Video.map((video, i) => (
                            <Grid
                                item
                                key={i}
                                xs={12}
                                md={4}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Stack key={i}>
                                    <TypographyContentTitle>{video.Title[context.language]}</TypographyContentTitle>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;