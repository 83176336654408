// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import WofooJosephLeePrimarySchool from '../../Partnership/Logos/WofooJosephLeePrimarySchool.jpg';
import WofooJosephLeePrimarySchool_Video1 from './WofooJosephLeePrimarySchool_Video1.jpg';
import WofooJosephLeePrimarySchool_Video2 from './WofooJosephLeePrimarySchool_Video2.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import TypographyContents from '../../common/TypographyContents';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'WFJosephLeePrimarySchool'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={WofooJosephLeePrimarySchool} sx={{ height: '50px' }} />
                {Text.CaseSharing.WFJosephLeePrimarySchool.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContentTitle>
                    {Text.CaseSharing.WFJosephLeePrimarySchool.Intro.Title[context.language]}
                </TypographyContentTitle>
                <TypographyContents contents={Text.CaseSharing.WFJosephLeePrimarySchool.Intro.Content[context.language]} />
                <Box sx={{ maxWidth: '600px', position: 'relative', }}>
                    <Box
                        component="img"
                        src={WofooJosephLeePrimarySchool_Video1}
                        sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                    />
                    <DialogVideo url={Text.CaseSharing.WFJosephLeePrimarySchool.Video[0].URL} />
                </Box>
                <TypographyContentTitle>
                    {Text.CaseSharing.WFJosephLeePrimarySchool.Intro2.Title[context.language]}
                </TypographyContentTitle>
                <TypographyContents contents={Text.CaseSharing.WFJosephLeePrimarySchool.Intro2.Content[context.language]} />
                <Box sx={{ maxWidth: '600px', position: 'relative', }}>
                    <Box
                        component="img"
                        src={WofooJosephLeePrimarySchool_Video2}
                        sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                    />
                    <DialogVideo url={Text.CaseSharing.WFJosephLeePrimarySchool.Video[1].URL} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;