// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Stack, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
//import { ContextApp } from '../common/ContextApp.js';
//import { Text } from '../common/Text.js';

const StackCase = ({ caseID, image, services, setOpenDialog, title }) => {
    // TODO: Define useContext
    //const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Stack onClick={() => setOpenDialog(caseID)} sx={{ height: 1 }}>
            <Box
                component="img"
                src={image}
                sx={{ aspectRatio: '16/9', objectFit: 'cover', width: 1, }}
            />
            <Box sx={{ backgroundColor: 'primary.light', flex: 1 }}>
                <Typography sx={{ color: 'white', fontWeight: 'bold', mt: 1, textAlign: 'center' }} variant="h6">
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', m: 2, mt: 1 }}>
                    {
                        services.map((text, j) => (
                            <React.Fragment key={j}>
                                {j > 0 && <Typography color="white" sx={{ mx: 1 }} variant="body1">|</Typography>}
                                <Typography color="white" variant="body1">{text}</Typography>
                            </React.Fragment>
                        ))
                    }
                </Box>
            </Box>
        </Stack>
    );
}

export default StackCase;