// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Container, Grid, List, ListItem, ListItemIcon, ListItemText,Typography, } from '@mui/material';

// TODO: Import MUI icon
import { RadioButtonChecked } from '@mui/icons-material';

// TODO: Import custom component
import TypographyContents from '../common/TypographyContents';
import TypographySubitle from '../common/TypographySubitle';
import TypographyContentTitle from '../common/TypographyContentTitle';

import TVProgram_OwnSweetHome from './TVProgramWriting/TVProgram_OwnSweetHome.jpg'

import { ContextApp } from '../common/ContextApp';
import { Text } from '../common/Text';

export default function TabTVProgramWriting() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    const ContentGrid1 = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', }}>
                <Box
                    component="img"
                    src={TVProgram_OwnSweetHome}
                    sx={{ borderRadius: 5, objectFit: 'cover', width: 0.8, }}
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                <List>
                    {
                        Text.OurServices.Services.TVProgramWriting.ContentGrid1[context.language].map((duty, i) => (
                            <ListItem key={i} sx={{ pb: 0, pt: 0 }}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                    <RadioButtonChecked color="primary" fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={duty} sx={{ mb: 0 }} />
                            </ListItem>
                        ))
                    }
                </List>
            </Grid>
        </Grid>
    );

    return (
        <Container>
            <TypographySubitle>{Text.OurServices.Services.TVProgramWriting.Title[context.language]}</TypographySubitle>
            <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold', mb: 2, textAlign: 'center', }} variant="h6">
                {Text.OurServices.Services.TVProgramWriting.Slogan[context.language]}
            </Typography>
            {
                Text.OurServices.Services.TVProgramWriting.Content.map((content, i) =>
                    <React.Fragment key={i}>
                        <TypographyContentTitle>
                            {content.Title[context.language]}
                        </TypographyContentTitle>
                        <TypographyContents contents={content.Content[context.language]} />
                        {i === 1 && <ContentGrid1 />}
                    </React.Fragment>
                )
            }
        </Container>
    );
}