// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon

// TODO: Import custom component
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';
import AboutUs from './AboutUs.webp';


export default function ContainerAboutUs() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/AboutUs');
    }

    // TODO: Define function

    // TODO: Return component
    return (
        <Container sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 0, sm: 3 },
            pt: 2
        }}>
            <Box
                alt="About us"
                component="img"
                loading="lazy"
                src={AboutUs}
                sx={{ objectFit: 'scale-down', maxWidth: 1 }}
            />
            <Stack>
                <Typography color="primary" sx={{ fontWeight: 'bold', textAlign: 'center', }} variant="h5">
                    {Text.Home.AboutUs.Title[context.language]}
                </Typography>
                <Typography color="primary" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center', }} variant="h5">
                    {Text.Common.CompanyFullName['ENG']}
                </Typography>
                {
                    Text.Home.AboutUs.Content[context.language].map((item, i) => (
                        <Typography key={i} sx={{ mb: 2 }} variant="body1">
                            {item}
                        </Typography>
                    ))
                }
                <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                    <Button onClick={handleClick} variant="contained">
                        {Text.Common.More[context.language]}
                    </Button>
                </Box>
            </Stack>
        </Container>
    );
}