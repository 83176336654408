// TODO: Import React module
import * as React from 'react';
import { Helmet, } from 'react-helmet-async';

// TODO: Import custom component
import BoxLogos from './Partnership/BoxLogos.js'

import ContainerPage from './common/ContainerPage.js'
import TypographyTitle from './common/TypographyTitle.js';
import TypographySubitle from './common/TypographySubitle.js';
import { ContextApp } from './common/ContextApp.js';
import { Text } from './common/Text.js';

export default function Partnership() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Return component
    return (
        <ContainerPage>
            <Helmet>
                <title>AFG Creative House</title>
                <meta name="description" content="我們是一間本地的公關公司，致力透過經營與傳媒及各持份者的關係，提升品牌價值。創辦人接近十年的媒體經驗，深諳傳媒生態，無論取材角度，抑或連繫時下當紅的藝人合作，均能為品牌塑造新聞價值，引起熱話，爭取最大化曝光。" />
                <meta name="keywords" content="AFG, Creative, AFG Creative House, 品牌文案撰寫, 媒體關係, 連繫名人與品牌合作, 宣傳片製作, 公關活動策劃, 電視節目撰稿" />
            </Helmet>
            <TypographyTitle>{Text.Partnership.Title[context.language]}</TypographyTitle>
            <TypographySubitle>{Text.Partnership.Thankyou[context.language]}</TypographySubitle>
            <BoxLogos />
        </ContainerPage>
    );
}