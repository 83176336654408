// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import custom component
import Zibo from '../../Partnership/Logos/Zibo.webp';
import Image1 from './Image1.jpg';
import Image2 from './Image2.jpg';
import Image3 from './Image3.jpg';
import Image4 from './Image4.jpg';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'Zibo'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={Zibo} sx={{ height: '50px' }} />
                {Text.CaseSharing.Zibo.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContents contents={Text.CaseSharing.Zibo.Intro[context.language]} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image1} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image2} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image3} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image4} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;