// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Alert, Button, Container, Grid, Snackbar, TextField } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';
import TypographyTitle from '../common/TypographyTitle.js';

export default function ContainerContactUs() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const [valueName, setValueName] = React.useState('');
    const [valueEmail, setValueEmail] = React.useState('');
    const [valueNo, setValueNo] = React.useState('');
    const [valueSubject, setValueSubject] = React.useState('');
    const [valueMessage, setValueMessage] = React.useState('');

    const [errorName, setErrorName] = React.useState(false);
    const [errorEmail, setErrorEmail] = React.useState(false);
    const [errorNo, setErrorNo] = React.useState(false);
    const [errorSubject, setErrorSubject] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    // TODO: Define event
    const handleClick = () => {
        setErrorName(valueName.length === 0);
        setErrorEmail(valueEmail.length === 0 && valueNo.length === 0);
        setErrorNo(valueEmail.length === 0 && valueNo.length === 0);
        setErrorSubject(valueSubject.length === 0);
        setErrorMessage(valueMessage.length === 0);

        if (
            valueName.length !== 0 &&
            (valueEmail.length !== 0 || valueNo.length !== 0) &&
            valueSubject.length !== 0 &&
            valueMessage.length !== 0
        ) {
            SendEmail();
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // TODO: Define function
    function SendEmail() {
        (async () => {
            const formData = new FormData();
            formData.append('Name', valueName);
            formData.append('Email', valueEmail);
            formData.append('No', valueNo);
            formData.append('Subject', valueSubject);
            formData.append('Message', valueMessage);

            fetch('https://www.pohtyh.edu.hk/~leyland/AFG/SendMessage.php', { method: 'POST', body: formData, })
                .then((response) => response.json())
                .then((result) => {
                    setOpen(true);
                })
                .catch((error) => { console.error('Error:', error); });
        })();
    }

    // TODO: Return component
    return (
        <Container sx={{ mb: 5, pt: 2 }}>
            <TypographyTitle>{Text.Home.ContactUs.Title[context.language]}</TypographyTitle>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <TextField
                        error={errorName}
                        fullWidth
                        helperText={errorName ? Text.Home.ContactUs.PleaseFill[context.language] : ''}
                        label={Text.Home.ContactUs.Name[context.language]}
                        onChange={(e) => { setValueName(e.target.value); setErrorName(false); }}
                        required
                        value={valueName}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        error={errorEmail}
                        fullWidth
                        helperText={errorEmail ? Text.Home.ContactUs.PleaseFillContact[context.language] : ''}
                        label={Text.Home.ContactUs.Email[context.language]}
                        onChange={(e) => { setValueEmail(e.target.value); setErrorEmail(false); setErrorNo(false) }}
                        value={valueEmail}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        error={errorNo}
                        fullWidth
                        helperText={errorNo ? Text.Home.ContactUs.PleaseFillContact[context.language] : ''}
                        label={Text.Home.ContactUs.ContactNo[context.language]}
                        onChange={(e) => { setValueNo(e.target.value); setErrorEmail(false); setErrorNo(false) }}
                        value={valueNo}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errorSubject}
                        fullWidth
                        helperText={errorSubject ? Text.Home.ContactUs.PleaseFill[context.language] : ''}
                        label={Text.Home.ContactUs.Subject[context.language]}
                        onChange={(e) => { setValueSubject(e.target.value); setErrorSubject(false) }}
                        required
                        value={valueSubject}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errorMessage}
                        fullWidth
                        helperText={errorMessage ? Text.Home.ContactUs.PleaseFill[context.language] : ''}
                        label={Text.Home.ContactUs.Message[context.language]}
                        multiline
                        onChange={(e) => { setValueMessage(e.target.value); setErrorMessage(false) }}
                        required
                        rows={5}
                        value={valueMessage}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleClick} variant="contained">{Text.Home.ContactUs.Send[context.language]}</Button>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {Text.Home.ContactUs.EmailSent[context.language]}
                </Alert>
            </Snackbar>
        </Container>
    );
}