// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Container, Typography, Unstable_Grid2 as Grid } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import TypographyContents from '../common/TypographyContents';
import TypographySubitle from '../common/TypographySubitle';
import TypographyContentTitle from '../common/TypographyContentTitle';
import { ContextApp } from '../common/ContextApp';
import { Text } from '../common/Text';

import Primeval from './BrandsCooperation/Primeval.jpg';
import yNotFurniture from './BrandsCooperation/yNotFurniture.jpg';

export default function TabBrandsCooperation() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Container>
            <TypographySubitle>{Text.OurServices.Services.BrandsCooperation.Title[context.language]}</TypographySubitle>
            <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold', mb: 2, textAlign: 'center', }} variant="h6">
                {Text.OurServices.Services.BrandsCooperation.Slogan[context.language]}
            </Typography>
            {
                Text.OurServices.Services.BrandsCooperation.Content.map((content, i) =>
                    <React.Fragment key={i}>
                        <TypographyContentTitle>
                            {content.Title[context.language]}
                        </TypographyContentTitle>
                        <TypographyContents contents={content.Content[context.language]} />
                    </React.Fragment>
                )
            }
            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', maxWidth: '600px' }}>
                <Grid xs={6}>
                    <Box component="img" src={Primeval} sx={{ width: 1 }} />
                </Grid>
                <Grid xs={6}>
                    <Box component="img" src={yNotFurniture} sx={{ width: 1 }} />
                </Grid>
                </Grid>
            </Box>
        </Container>
    );
}