// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon;
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';

// TODO: Import custom component
import BGFounder from './bgFounder.jpg'

import BoxSubtitle from '../common/BoxSubtitle.js';
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function GridIntro() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Grid container spacing={2} sx={{mt:2}}>
            <Grid
                item
                xs={12}
                md={6}
                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
            >
                <Stack>
                    <BoxSubtitle icon={<EmojiPeopleRoundedIcon />} text={Text.AboutUs.Founder.Title[context.language]} />
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: Text.AboutUs.Founder.Content[context.language]
                        }}
                        variant="body"
                    />
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    alt="Founder Introduction"
                    component="img"
                    src={BGFounder}
                    sx={{
                        borderRadius: 5,
                        objectFit: 'contain',
                        width: 0.5,
                    }}
                />
            </Grid>
        </Grid>
    );
}