// TODO: Import React module
import * as React from 'react';
import { Helmet, } from 'react-helmet-async';

export default function HelmetCommon() {
    // TODO: Return component
    return (
        <Helmet>
            <title>AFG Creative House</title>
            <meta name="description" content="�ڭ̬O�@�����a���������q�A�P�O�z�L�g��P�ǴC�ΦU�����̪����Y�A���ɫ~�P���ȡC�п�H����Q�~���C��g��A�`�ڶǴC�ͺA�A�L�ר������סA���γsô�ɤU���������H�X�@�A���ର�~�P��y�s�D���ȡA�ް_���ܡA�����̤j���n���C" />
            <meta name="keywords" content="AFG, Creative, AFG Creative House, �~�P��׼��g, �C�����Y, �sô�W�H�P�~�P�X�@, �ŶǤ��s�@, �������ʵ���, �q���`�ؼ��Z" />
        </Helmet>
    );
}