// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
    List, ListItem, ListItemIcon, ListItemText, Stack,
} from '@mui/material';

// TODO: Import MUI icon
import { RadioButtonChecked } from '@mui/icons-material';

// TODO: Import custom component
import YTYSKG from '../../Partnership/Logos/YTYSKG.jpg';
import Image1 from './Image1.webp';
import Image2 from './Image2.webp';
import Image3 from './Image3.webp';
import Image4 from './Image4.webp';
import Image5 from './Image5.webp';
import Image6 from './Image6.webp';
import Image7 from './Image7.webp';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'YTYSKG'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={YTYSKG} sx={{ height: '50px' }} />
                {Text.CaseSharing.YTYSKG.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContents contents={Text.CaseSharing.YTYSKG.Intro.Content[context.language]} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image1} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image2} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image3} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image5} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image6} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image7} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;