// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';

// TODO: Import custom component
import SocialMedia_BoBun from './SocialMedia_BoBun.jpg'
import SocialMedia_BoBun2 from './SocialMedia_BoBun2.jpg'
import SocialMedia_Cedar from './SocialMedia_Cedar.jpg'
import SocialMedia_Cedar2 from './SocialMedia_Cedar2.jpg'
import SocialMedia_ThePrefaceHK from './SocialMedia_ThePrefaceHK.jpg'
import SocialMedia_YiYuan from './SocialMedia_YiYuan.jpg'

export default function BoxForm() {
    // TODO: Define useContext

    // TODO: Define variable
    //const [activeIndex, setActiveIndex] = React.useState(0);
    //const [checked, setChecked] = React.useState(true);
    const [transition, setTransition] = React.useState(true);
    const [largeImage, setLargeImage] = React.useState(1);

    const [numOfImages, setNumOfImages] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);
    const [imageWidth, setImageWidth] = React.useState();
    const fadeTime = 500;
    const fadeInterval = 1500;

    const images = [
        SocialMedia_BoBun,
        SocialMedia_BoBun2,
        SocialMedia_Cedar,
        SocialMedia_Cedar2,
        SocialMedia_ThePrefaceHK,
        SocialMedia_YiYuan,

        SocialMedia_BoBun,
        SocialMedia_BoBun2,
        SocialMedia_Cedar,
        SocialMedia_Cedar2,
        SocialMedia_ThePrefaceHK,
        SocialMedia_YiYuan,
    ];

    // TODO: Define event
    const handleResize = () => {
        switch (true) {
            case window.innerWidth >= 1200:
                setContainerWidth(720);
                setNumOfImages(4);
                break;
            case window.innerWidth >= 900:
                setContainerWidth(495);
                setNumOfImages(3);
                break;
            default:
                setContainerWidth(200);
                setNumOfImages(3);
        }
    };

    React.useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    React.useEffect(() => {
        console.log('containerWidth', containerWidth);
        setImageWidth(containerWidth / numOfImages);
    }, [containerWidth, numOfImages]);

    React.useEffect(() => {
        console.log('imageWidth', imageWidth);
    }, [imageWidth]);

    React.useEffect(() => {
        console.log('largeImage', largeImage);
        if (largeImage <= images.length / 2) {
            setTimeout(() => {
                setTransition(true);
                setLargeImage(largeImage + 1);
            }, fadeInterval);
        } else {
            setTimeout(() => {
                setTransition(false);
                setLargeImage(1);
            }, fadeTime);
        }
    }, [images.length, largeImage]);

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Box sx={{
                display: 'flex',
                mt: { xs: 5, md: 10 },
                maxWidth: containerWidth,
                overflow: 'hidden',
                py: 5,
                position: 'relative',
            }}>
                <Box sx={{
                    background: 'linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
                    left: 0,
                    position: 'absolute',
                    width: 50,
                    height: '100%',
                    zIndex: 10,
                }}>
                </Box>
                <Box sx={{
                    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                    right: 0,
                    position: 'absolute',
                    width: 50,
                    height: '100%',
                    zIndex: 10,
                }}>
                </Box>
                {
                    images.map((image, i) => (
                        <Box
                            component="img"
                            key={i}
                            src={image}
                            sx={{
                                border: '1px solid #CCC',
                                borderRadius: 5,
                                float: 'left',
                                left: `${(largeImage - 1) * imageWidth * -1}px`,
                                objectFit: 'cover',
                                position: 'relative',
                                transform: (i === largeImage) || (i === largeImage + images.length / 2) ? 'scale(1.2)' : 'none',
                                transition: transition ? `left ${fadeTime / 1000}s ease` : 'none',
                                width: `${imageWidth}px`,
                                zIndex: (i === largeImage) || (i === largeImage + images.length / 2) ? 10 : 0,
                            }}
                        />
                    ))
                }
            </Box>
        </Box>
    );
}