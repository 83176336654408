// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import PoLeungKuk from '../../Partnership/Logos/PoLeungKuk.jpg';
import PoLeungKuk_Video1 from './PoLeungKuk_Video1.jpg';
import PoLeungKuk_Video2 from './PoLeungKuk_Video2.jpg';
import TypographyContents from '../../common/TypographyContents';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
	// TODO: Define useContext
	const context = React.useContext(ContextApp);

	// TODO: Define variable
	const videoImage = [
		PoLeungKuk_Video2,
		PoLeungKuk_Video1,
	];

	// TODO: Define event
	const handleClose = () => {
		setOpenDialog(null);
	};

	// TODO: Define function

	// TODO: Return component
	return (
		<Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'PoLeungKuk'} onClose={handleClose}>
			<DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
				<Box component="img" src={PoLeungKuk} sx={{ height: '50px' }} />
				{Text.CaseSharing.PoLeungKuk.Name[context.language]}
			</DialogTitle>
			<DialogContent>
				<TypographyContents contents={Text.CaseSharing.PoLeungKuk.Intro.Content[context.language]} />
				<Grid container spacing={2}>
					{
						Text.CaseSharing.PoLeungKuk.Videos.map((video, i) => (
							<Grid
								item
								key={i}
								sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
								xs={12} md={4}
							>
								<Stack key={i}>
									<Box sx={{ position: 'relative' }}>
										<Box
											component="img"
											src={videoImage[i]}
											sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
										/>
									</Box>
								</Stack>
							</Grid>
						))
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogCase;