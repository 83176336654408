import { createTheme } from '@mui/material/styles';

export const ThemeAFG = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: '#FF42A4',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: '#FF42A4',
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#FF5CAC',
            main: '#FF42A4',
            dark: '#E151AF',
            contrastText: '#FFFFFF',
        },
        secondary: {
            light: '#FFEBEE',
            main: '#F7D6D0',
            dark: '#FABEC0',
            contrastText: '#FFFFFF',
        },
        text: {
            primary: '#555555',
        },
    },
});