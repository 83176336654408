// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import AMoment from '../../Partnership/Logos/AMoment.jpg';
import AMOMENT_Intro from './AMOMENT_Intro.jpg';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'AMOMENT'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={AMoment} sx={{ height: '50px' }} />
                {Text.CaseSharing.AMOMENT.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Stack>
                            <TypographyContentTitle>
                                {Text.CaseSharing.AMOMENT.Intro.Title[context.language]}
                            </TypographyContentTitle>
                            <Typography variant="body">
                                {Text.CaseSharing.AMOMENT.Intro.Content[context.language]}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={AMOMENT_Intro} sx={{ borderRadius: 5, objectFit: 'contain', width: 1, }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;