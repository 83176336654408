// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// TODO: Import MUI icon;

// TODO: Import custom component
import TVProgram_8HongKong from './TVProgram_8HongKong.jpg';
import TVProgram_Camping from './TVProgram_Camping.jpg';
import TVProgram_GrandpaCooking from './TVProgram_GrandpaCooking.jpg';
import TVProgram_OrganizedDining from './TVProgram_OrganizedDining.jpg';
import TVProgram_SammyOnTheGo from './TVProgram_SammyOnTheGo.jpg';
import TVProgram_Scoop from './TVProgram_Scoop.jpg';

export default function GridParticipatedPrograms() {
    // TODO: Define useContext

    // TODO: Define variable
    const TVPrograms = [
        TVProgram_8HongKong,
        TVProgram_Camping,
        TVProgram_GrandpaCooking,
        TVProgram_OrganizedDining,
        TVProgram_SammyOnTheGo,
        TVProgram_Scoop,
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Grid
            container
            spacing={1}
            sx={{
                mt: 2,
                '& .MuiGrid-item': { pt: '0 !important' },
            }}
        >
            {
                TVPrograms.map((image, i) => (
                    <Grid
                        item
                        key={i}
                        md={4}
                        xs={6}
                    >
                        <Box
                            alt="Reference Photo"
                            component="img"
                            key={i}
                            src={image}
                            sx={{
                                objectFit: 'cover',
                                width: 1,
                                height: {
                                    xs: `calc(50vw * 9 / 16)`,
                                    md: `calc(30vw * 9 / 16)`,
                                },
                            }}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}