// TODO: Import React module
import * as React from 'react';

// TODO: Import custom component
import HelmetCommon from './common/HelmetCommon.js';
import ContainerPage from './common/ContainerPage.js';
import TabServices from './OurServices/TabServices.js'
import TypographyTitle from './common/TypographyTitle.js';
import { ContextApp } from './common/ContextApp.js';
import { Text } from './common/Text.js';

export default function AboutUs() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define event
    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    // TODO: Return component
    return (
        <ContainerPage>
            <HelmetCommon />
            <TypographyTitle>{Text.OurServices.Title[context.language]}</TypographyTitle>
            <TabServices />
        </ContainerPage>
    );
}