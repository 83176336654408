import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stepper from './Stepper.js';

const DialogArticle = ({ article, onClose }) => {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    return (
        <Dialog
            maxWidth={'lg'}
            open={Boolean(article)}
            onClose={onClose}
        >
            {
                article &&
                <>
                    <DialogTitle id="scroll-dialog-title">{article.Title}</DialogTitle>
                    <DialogContent>
                        <Stepper imagePath={article.ImagePath} />
                        <DialogContentText>
                            <Typography style={{ whiteSpace: 'pre-line' }} component={'span'}>
                                {article.Content}
                            </Typography>
                            {
                                article.Tag && article.Tag.length > 0 &&
                                <Typography component="span" sx={{ color: 'blue', mt: 2, whiteSpace: 'pre-line' }} variant="body2">
                                    {article.Tag}
                                </Typography>
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>關閉</Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    );
};

export default DialogArticle;