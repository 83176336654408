// TODO: Import React module
import * as React from 'react';
//import HTMLFlipBook from 'react-pageflip';

// TODO: Import MUI module
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
/*import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';*/
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import { Article } from '@mui/icons-material';

// TODO: Import custom component
import BoxSubtitle from '../common/BoxSubtitle.js';
/*import ContainerPage from '../common/ContainerPage.js';
import TypographyTitle from '../common/TypographyTitle.js';*/
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

import Dialog from './Dialog.js';

export default function Articles() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const [articles, setArticles] = React.useState([]);
    const [popupArticles, setPopupArticles] = React.useState(null);

    // TODO: Define event
    const handleOpenDialog = (article) => {
        setPopupArticles(article);
    }

    const handleCloseDialog = () => {
        setPopupArticles(null);
    }

    // TODO: Fetch articles data
    React.useEffect(() => {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetArticles');

            fetch(
                context.webserviceURL,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log('Articles loaded:', result);

                    setArticles(result);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })();
    }, [context.webserviceURL]);

    return (
        <>
            <BoxSubtitle icon={<Article />} text={Text.AFGHome.Articles[context.language]} />

            <Grid container spacing={2}>
                {articles.map((item, i) => (
                    <Grid key={i} xs={4}>
                        <Box
                            onClick={() => { handleOpenDialog(item) }}
                            sx={{ backgroundColor: '#EEE', height: 1 }}
                        >
                            <Box
                                alt={item.Title}
                                component="img"
                                src={`./Articles/${item.ImagePath.split(';')[0]}`}
                                srcSet={`./Articles/${item.ImagePath.split(';')[0]}`}
                                loading="lazy"
                                sx={{ width: 1 }}
                            />
                            <Typography
                                sx={{
                                    fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
                                    fontWeight: 'bold',
                                    px: 1,
                                }}
                            >
                                {item.Title}
                            </Typography>
                            <Typography
                                sx={{color:'#999',px: 1,mb:1}}
                                variant="body2"
                            >
                                {item.Series} {item.PDate}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid >
            <Dialog article={popupArticles} onClose={handleCloseDialog} />
        </>
    );
}
