// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import TICA from '../../Partnership/Logos/TICA.jpg';
import Newspaper from './Newspaper.jpg';
import Video1 from './Video1.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const videoImage = [
        Video1,
    ];

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'TICA'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={TICA} sx={{ height: '50px' }} />
                {Text.CaseSharing.TICA.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <Stack>
                    <TypographyContentTitle>
                        {Text.CaseSharing.TICA.MediaExposure.Title[context.language]}
                    </TypographyContentTitle>
                    <TypographyContents contents={Text.CaseSharing.TICA.MediaExposure.Intro[context.language]} />
                </Stack>
                <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                    {
                        Text.CaseSharing.TICA.MediaExposure.Video.map((video, i) => (
                            <Grid item key={i} xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                                <Stack key={i}>
                                    <TypographyContentTitle>{video.Title[context.language]}</TypographyContentTitle>
                                    <Box sx={{ position: 'relative' }}>
                                        <Box
                                            component="img"
                                            src={videoImage[i]}
                                            sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                                        />
                                        <DialogVideo url={video.URL} />
                                    </Box>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>
                <Stack sx={{ mt: 2 }}>
                    {/*<TypographyContentTitle>
                            {Text.CaseSharing.TICA.CorporateImage.Title[context.language]}
                        </TypographyContentTitle>*/}
                    <Typography variant="body">{Text.CaseSharing.TICA.CorporateImage.Intro[context.language]}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            src={Newspaper}
                            sx={{
                                borderRadius: 5,
                                objectFit: 'cover',
                                width: { xs: 1, md: 0.4 },
                            }}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;