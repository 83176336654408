// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon;

// TODO: Import custom component
import TypographyTitle from '../common/TypographyTitle.js';
import BGImage from './bgImage.jpg'
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function GridIntro() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', }}>
                <Box
                    alt="Introduction"
                    component="img"
                    src={BGImage}
                    sx={{ borderRadius: 5, objectFit: 'cover', width: { xs: 1, md: "150%" }, }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap:2 }}
            >
                <TypographyTitle>{Text.AboutUs.Title[context.language]}</TypographyTitle>
                {
                    Text.AboutUs.Content[context.language].map((item, i) =>
                        <Typography key={i} variant="body1">{item}</Typography>
                    )
                }
            </Grid>
        </Grid>
    );
}