// TODO: Import React module
import * as React from 'react';
import ReactPlayer from 'react-player/youtube';

// TODO: Import MUI module
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

// TODO: Import MUI icon
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

// TODO: Import custom component

export default function DialogVideo(props) {
    // TODO: Define useContext

    // TODO: Define variable
    const [open, setOpen] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);

    // TODO: Define event
    const handleClickOpen = () => {
        setOpen(true);
        setPlaying(true);
    };

    const handleClose = () => {
        setOpen(false);
        setPlaying(false);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <>
            <IconButton
                color="primary"
                onClick={handleClickOpen}
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '56px',
                    height: '56px',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                }}
            >
                <PlayArrowIcon />
            </IconButton>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogActions>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CancelRoundedIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{
                    backgroundColor: '#F7D6D0',
                    display: 'flex',
                    justifyContent: 'center',
                    pb:5,
                }}>
                    <ReactPlayer
                        controls={true}
                        playing={playing}
                        url={props.url}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}