// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

// TODO: Import MUI icon
import { Diversity1, Diversity3, HistoryEdu, Label, LiveTv, TipsAndUpdates, Videocam, } from '@mui/icons-material';

// TODO: Import custom component
import Service_ResearchWriting from '../OurServices/Service_ResearchWriting.webp';
import Service_BrandStoryWriting from '../OurServices/Service_BrandStoryWriting.webp';
import Service_VideoContentCreation from '../OurServices/Service_VideoContentCreation.webp';
import Service_WritingServices from '../OurServices/Service_WritingServices.webp';
import Service_WebpageContentCreation from '../OurServices/Service_WebpageContentCreation.webp';
import Service_SocialMediaContentCreation from '../OurServices/Service_SocialMediaContentCreation.webp';
import TypographySubtitleH6WithIcon from '../common/TypographySubtitleH6WithIcon.js';
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function GridServices() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const services = {
        BrandCopywriting: {
            Icon: <HistoryEdu />,
            Img: Service_SocialMediaContentCreation,
        },
        MediaRelations: {
            Icon: <Diversity1 />,
            Img: Service_ResearchWriting,
        },
        BrandsCooperation: {
            Icon: <Diversity3 />,
            Img: Service_BrandStoryWriting
        },
        PromotionalVideoProduction: {
            Icon: <Videocam />,
            Img: Service_VideoContentCreation
        },
        EventPlanning: {
            Icon: <TipsAndUpdates />,
            Img: Service_WritingServices
        },
        TVProgramWriting: {
            Icon: <LiveTv />,
            Img: Service_WebpageContentCreation
        },
    };

    // TODO: Define event
    const navigate = useNavigate();

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{ '& .MuiGrid-container': { marginTop: '0 !important', paddingBottom: '16px !important', }, }}>
            <Grid container spacing={{ xs: 2, md: 5 }}>
                {
                    Object.keys(Text.OurServices.Services).map((service, i) => (
                        <React.Fragment key={i}>
                            {
                                services[service] &&
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Stack>
                                        <Box
                                            alt="Service"
                                            component="img"
                                            src={services[service].Img}
                                            sx={{
                                                borderRadius: 5,
                                                height: {
                                                    xs: `calc(100vw * 9 / 16)`,
                                                    sm: `calc(50vw * 9 / 16)`,
                                                    md: `calc(30vw * 9 / 16)`,
                                                },
                                                objectFit: 'cover',
                                                width: 1,
                                            }}
                                        />
                                        <TypographySubtitleH6WithIcon
                                            icon={services[service].Icon}
                                            text={Text.OurServices.Services[service].Title[context.language]}
                                            textColor="white"
                                        />
                                        <List disablePadding={true} sx={{ flex: 1 }}>
                                            {
                                                Array.isArray(Text.OurServices.Services[service].Intro) &&
                                                Text.OurServices.Services[service].Intro.map((item, i) => (
                                                    <ListItem disablePadding={true} key={i} sx={{ alignItems: 'start' }}>
                                                        <ListItemIcon sx={{ color: 'white' }}>
                                                            <Label />
                                                        </ListItemIcon>
                                                        <ListItemText primary={item[context.language]} sx={{ color: 'white' }} />
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                            {
                                                <Button
                                                    onClick={() => navigate('/OurServices#' + i)}
                                                    sx={{ backgroundColor: 'primary.light' }}
                                                    variant="contained"
                                                >
                                                    {Text.Common.More[context.language]}
                                                </Button>
                                            }
                                        </Box>
                                    </Stack>
                                </Grid>
                            }
                        </React.Fragment>
                    ))
                }
            </Grid>
        </Box>
    );
}