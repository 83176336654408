// TODO: Import React module
import * as React from 'react';
import { Helmet, } from 'react-helmet-async';

// TODO: Import MUI module

// TODO: Import MUI icon

// TODO: Import custom component
import ContainerContactUs from './Home/ContainerContactUs.js';
import GridInfo from './ContactUs/GridInfo.js';

import ContainerPage from './common/ContainerPage.js'
import TypographyTitle from './common/TypographyTitle.js';
import { ContextApp } from './common/ContextApp.js';
import { Text } from './common/Text.js';

export default function ContactUs() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    React.useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: Define function

    // TODO: Return component
    return (
        <ContainerPage>
            <Helmet>
                <title>AFG Creative House</title>
                <meta name="description" content="我們是一間本地的公關公司，致力透過經營與傳媒及各持份者的關係，提升品牌價值。創辦人接近十年的媒體經驗，深諳傳媒生態，無論取材角度，抑或連繫時下當紅的藝人合作，均能為品牌塑造新聞價值，引起熱話，爭取最大化曝光。" />
                <meta name="keywords" content="AFG, Creative, AFG Creative House, 品牌文案撰寫, 媒體關係, 連繫名人與品牌合作, 宣傳片製作, 公關活動策劃, 電視節目撰稿" />
            </Helmet>
            <TypographyTitle>{Text.ContactUs.Title[context.language]}</TypographyTitle>
            <GridInfo />
            <iframe
                frameBorder="0"
                loading="lazy"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d753.620212434063!2d114.12326533766054!3d22.357955374466005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f8befe927003%3A0x4eac653f7cd9f339!2sYee%20Lim%20Godown%20Industrial%20Center%20Block%20B!5e0!3m2!1szh-TW!2shk!4v1689560601675!5m2!1szh-TW!2shk"
                style={{ marginTop: '16px', width: '100%', height: '480px' }}
                title="Google Map"
            >
            </iframe>
            <ContainerContactUs />
        </ContainerPage>
    );
}