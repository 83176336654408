// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Container, Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import TypographyContents from '../common/TypographyContents';
import TypographySubitle from '../common/TypographySubitle';
import TypographyContentTitle from '../common/TypographyContentTitle';

import Brand_CMCLHK from './BrandCopywriting/Brand_CMCLHK.jpg'
import Brand_HolaPhysiotherapy from './BrandCopywriting/Brand_HolaPhysiotherapy.jpg'
import Web_Hola from './BrandCopywriting/Web_Hola.jpg'
import Web_WAYIP from './BrandCopywriting/Web_WAYIP.jpg'
import Writing_ONest from './BrandCopywriting/Writing_ONest.jpg'
import Writing_TPOS from './BrandCopywriting/Writing_TPOS.jpg'

import BoxSlider from './SocialMediaContentCreation/BoxSlider.js'

import { ContextApp } from '../common/ContextApp';
import { Text } from '../common/Text';

export default function TabBrandCopywriting() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    const ContentGrid0 = () => (
        <Grid container spacing={1} sx={{ mt: 2, '& .MuiGrid-item': { pt: '0 !important' }, }}>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Brand_CMCLHK}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(50vw * 9 / 16)`, }, }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Brand_HolaPhysiotherapy}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(50vw * 9 / 16)`, }, }}
                />
            </Grid>
        </Grid>
    );

    const ContentGrid1 = () => (
        <Grid container spacing={1} sx={{ mt: 2, '& .MuiGrid-item': { pt: '0 !important' }, }}>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Web_WAYIP}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(25vw * 9 / 16)`, }, }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Web_Hola}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(25vw * 9 / 16)`, }, }}
                />
            </Grid>
        </Grid>
    );

    const ContentGrid3 = () => (
        <Grid container spacing={1} sx={{ mt: 2, '& .MuiGrid-item': { pt: '0 !important' }, }}>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Writing_ONest}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(50vw * 9 / 16)`, }, }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Writing_TPOS}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(50vw * 9 / 16)`, }, }}
                />
            </Grid>
        </Grid>
    );

    return (
        <Container>
            <TypographySubitle>{Text.OurServices.Services.BrandCopywriting.Title[context.language]}</TypographySubitle>
            <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold',mb:2, textAlign: 'center', }} variant="h6">
                {Text.OurServices.Services.BrandCopywriting.Slogan[context.language]}
            </Typography>
            <TypographyContents contents={Text.OurServices.Services.BrandCopywriting.Description[context.language]} />
            {
                Text.OurServices.Services.BrandCopywriting.Content.map((content, i) =>
                    <React.Fragment key={i}>
                        <TypographyContentTitle>
                            {content.Title[context.language]}
                        </TypographyContentTitle>
                        <TypographyContents contents={content.Content[context.language]} />
                        {i === 0 && <ContentGrid0 />}
                        {i === 1 && <ContentGrid1 />}
                        {i === 2 && <BoxSlider />}
                        {i === 3 && <ContentGrid3 />}
                    </React.Fragment>
                )
            }
        </Container>
    );
}