// TODO: Import React module
import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// TODO: Import MUI module
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';

// TODO: Import custom component
import Footer from './AFG/Footer.js';
import MenuBar from './AFG/MenuBar.js';
import AboutUs from './AFG/AboutUs.js';
import Home from './AFG/Home.js';
import OurServices from './AFG/OurServices.js';
import Partnership from './AFG/Partnership.js';
import CaseSharing from './AFG/CaseSharing.js';
import ContactUs from './AFG/ContactUs.js';
import AFGHome from './AFG/AFGHome.js';

import { ContextAppProvider } from './AFG/common/ContextApp.js';
import { ThemeAFG } from './AFG/common/Theme.js';

export default function AFG() {
    // TODO: Return component
    const basename = window.location.hostname === 'www.pohtyh.edu.hk' ? '~leyland/AFG' : '/';

    return (
        <HelmetProvider>
            <ThemeProvider theme={ThemeAFG}>
                <CssBaseline />
                <ContextAppProvider>
                    <Router basename={basename}>
                        <MenuBar />
                        <Routes>
                            <Route element={<Home />} path="/" />
                            <Route element={<AboutUs />} path="/AboutUs" />
                            <Route element={<OurServices />} path="/OurServices" />
                            <Route element={<Partnership />} path="/Partnership" />
                            <Route element={<CaseSharing />} path="/CaseSharing" />
                            <Route element={<ContactUs />} path="/ContactUs" />
                            <Route element={<AFGHome />} path="/AFGHome" />
                        </Routes>
                        <Footer />
                    </Router>
                </ContextAppProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
}