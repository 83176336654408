// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {
    Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid,Stack, Typography,
} from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import Hummingbird from '../../Partnership/Logos/Hummingbird.jpg';
import Booklet1 from './Booklet1.jpg';
import Booklet2 from './Booklet2.jpg';
import Booklet3 from './Booklet3.jpg';
import Booklet4 from './Booklet4.jpg';
import Video1 from './Video1.jpg';
import Video2 from './Video2.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const videoImage = [
        Video1,
        Video2,
    ];

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'Hummingbird'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={Hummingbird} sx={{ height: '50px' }} />
                {Text.CaseSharing.Hummingbird.Name[context.language]}
            </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row', }, mt: 2, }}>
                        <Grid item xs={12} md={6} sx={{ /*alignItems: 'center', display: 'flex', justifyContent: 'center',*/ }}>
                            <TypographyContents contents={Text.CaseSharing.Hummingbird.Content[context.language]} />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                            <Box component="img" src={Booklet1} sx={{ borderRadius: 5, objectFit: 'cover', width: 0.8, }} />
                        </Grid>
                    </Grid>
                    {/*<Stack>
                        <TypographyContentTitle>
                            {Text.CaseSharing.Hummingbird.SalesBrochureWriting.Title[context.language]}
                        </TypographyContentTitle>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: Text.CaseSharing.Hummingbird.SalesBrochureWriting.Intro[context.language]
                            }}
                            variant="body"
                        />
                    </Stack>*/}
                    <Container maxWidth="md">
                        {/*<Grid
                            container
                            spacing={2}
                            sx={{
                                flexDirection: {
                                    xs: 'column-reverse',
                                    md: 'row',
                                },
                                mt: 2,
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: Text.CaseSharing.Hummingbird.SalesBrochureWriting.Content1[context.language]
                                    }}
                                    variant="body"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Booklet1}
                                    sx={{
                                        borderRadius: 5,
                                        objectFit: 'cover',
                                        width: 0.8,
                                    }}
                                />
                            </Grid>
                        </Grid>*/}
                        <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Booklet2}
                                    sx={{
                                        borderRadius: 5,
                                        objectFit: 'cover',
                                        width: 0.8,
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: Text.CaseSharing.Hummingbird.SalesBrochureWriting.Content2[context.language]
                                    }}
                                    variant="body"
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                flexDirection: {
                                    xs: 'column-reverse',
                                    md: 'row',
                                },
                                mt: 2,
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: Text.CaseSharing.Hummingbird.SalesBrochureWriting.Content3[context.language]
                                    }}
                                    variant="body"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Booklet3}
                                    sx={{
                                        borderRadius: 5,
                                        objectFit: 'cover',
                                        width: 0.8,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Booklet4}
                                    sx={{
                                        borderRadius: 5,
                                        objectFit: 'cover',
                                        width: 0.8,
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: Text.CaseSharing.Hummingbird.SalesBrochureWriting.Content4[context.language]
                                    }}
                                    variant="body"
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <TypographyContentTitle>
                        {Text.CaseSharing.Hummingbird.VideoProduction.Title[context.language]}
                    </TypographyContentTitle>
                    <Grid
                        container
                        spacing={2}
                    >
                        {
                            Text.CaseSharing.Hummingbird.VideoProduction.Video.map((video, i) => (
                                <Grid
                                    item
                                    key={i}
                                    xs={12}
                                    md={6}
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Stack key={i}>
                                        <TypographyContentTitle>{video.Title[context.language]}</TypographyContentTitle>
                                        <Box sx={{ position: 'relative' }}>
                                            <Box
                                                component="img"
                                                src={videoImage[i]}
                                                sx={{
                                                    borderRadius: 5,
                                                    objectFit: 'cover',
                                                    width: 1,
                                                }}
                                            />
                                            <DialogVideo url={video.URL} />
                                        </Box>
                                    </Stack>
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
                </DialogActions>
            </Dialog>
    );
};

export default DialogCase;