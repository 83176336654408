// TODO: Import React module
import * as React from 'react';
import HTMLFlipBook from 'react-pageflip';

// TODO: Import MUI module
import { } from '@mui/material';

// TODO: Import MUI icon
import { ImportContacts } from '@mui/icons-material';

// TODO: Import custom component
import BoxSubtitle from './common/BoxSubtitle.js';
import ContainerPage from './common/ContainerPage.js'
import TypographyTitle from './common/TypographyTitle.js';
import { ContextApp } from './common/ContextApp.js';
import { Text } from './common/Text.js';

import Articles from './AFGHome/Articles.js';

export default function AFGHome() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const [magazine, setMagazine] = React.useState([]);

    // TODO: Define event
    React.useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        (async () => {
            const formData = new FormData();
            formData.append('action', 'GetMagazinePages');

            fetch(
                context.webserviceURL,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log('Pages loaded:', result);
                    setMagazine(result);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })();
    }, [context.webserviceURL]);

    // TODO: Define function

    // TODO: Return component
    return (
        <ContainerPage>
            <TypographyTitle>{Text.AFGHome.Title[context.language]}</TypographyTitle>
            <BoxSubtitle icon={<ImportContacts />} text={Text.AFGHome.Magazine[context.language]} />
            <HTMLFlipBook
                showCover={true}
                width={400}
                height={400}
                minWidth={300}
                minHeight={300}
                size="stretch"
                style={{ backgroundImage: 'url(./Magazine/MagazineBG.jpg)' }}
            >
                {magazine.map((item) => (
                    item.ImagePath.split(';').map((img) => (
                        <div>
                            <img
                                alt={item.Title}
                                loading="lazy"
                                src={`./Magazine/${img}`}
                                style={{ width: '100%' }}
                            />
                        </div>
                    ))
                ))}
            </HTMLFlipBook>
            <Articles />
        </ContainerPage>
    );
}