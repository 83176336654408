// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Typography } from '@mui/material';

const TypographyContents = ({ contents }) => {
    // TODO: Return component
    return (
        contents.map((text, i) => <Typography key={i} sx={{ mb: 2 }} variant="body1">{text}</Typography>)
    );
};

export default TypographyContents;