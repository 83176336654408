// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Typography from '@mui/material/Typography';

export default function TypographyTitle(props) {
    // TODO: Return component
    return (
        <Typography
            color="primary"
            sx={{ fontWeight: 'bold', m: 2, textAlign: 'center', }}
            variant="h5"
        >
            {props.children}
        </Typography>
    );
}