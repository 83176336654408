// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Container from '@mui/material/Container';

export default function ContainerPage(props) {
    // TODO: Return component
    return (
        <Container
            sx={{
                mb: 4,
                mt: 4,
            }}
        >
            {props.children}
        </Container>
    );
}