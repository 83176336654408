// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
//import { ContextApp } from '../common/ContextApp.js';
//import { Text } from '../common/Text.js';

const BoxTitle = ({ children }) => {
    // TODO: Define useContext

    // TODO: Define variable

    // TODO: Define event

    // TODO: Return component
    return (
        <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', mt: 2, width: 1 }}>
            <Box sx={{
                backgroundColor: '#f8bbd0',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                color: 'white',
                display: 'inline-flex',
                ml: 0,
                p: 2,
                py:0.5,
            }}>
                <Typography color="white" sx={{fontWeight:'bold',}} variant="h6">{children}</Typography>
            </Box>
            <Box sx={{
                backgroundColor: '#f8bbd0',
                borderTopRightRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                width: 1,
                height: '10px',
            }}>
            </Box>
        </Box>
    );
};

export default BoxTitle;