// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import AKA from '../../Partnership/Logos/AKA.jpg';
import AKA_Video1 from './AKA_Video1.jpg';
import AKA_Video2 from './AKA_Video2.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContents from '../../common/TypographyContents';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const videoImage = [AKA_Video1, AKA_Video2,];

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Define function

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'AKA'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box alt="AKA" component="img" src={AKA} sx={{ height: '50px' }} />
                {Text.CaseSharing.AKA.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContents contents={Text.CaseSharing.AKA.Intro.Content[context.language]} />
                <Grid container spacing={2}>
                    {
                        Text.CaseSharing.AKA.Videos.map((video, i) => (
                            <Grid
                                item
                                key={i}
                                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                                xs={12} md={6}
                            >
                                <Box sx={{ position: 'relative' }}>
                                    <Box
                                        alt="Thumb"
                                        component="img"
                                        src={videoImage[i]}
                                        sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                                    />
                                    <DialogVideo url={video.URL} />
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;