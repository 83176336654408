// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function BoxSubtitle(props) {
    // TODO: Return component
    return (
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            mt: 1,
        }}>
            <Avatar
                color="primary"
                sx={{ mr: 1 }}
            >
                {props.icon}
            </Avatar>
            <Typography
                color="primary"
                sx={{ fontWeight: 'bold' }}
                variant="h5"
            >
                {props.text}
            </Typography>
        </Box>
    );
}