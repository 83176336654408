// TODO: Import React module
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// TODO: Import MUI module;
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// TODO: Import MUI icon
import { Diversity1, Diversity3, HistoryEdu, LiveTv, TipsAndUpdates, Videocam, } from '@mui/icons-material';

// TODO: Import custom component
import TabBrandCopywriting from './TabBrandCopywriting';
import TabMediaRelations from './TabMediaRelations';
import TabBrandsCooperation from './TabBrandsCooperation';
import TabPromotionalVideoProduction from './TabPromotionalVideoProduction';
import TabEventPlanning from './TabEventPlanning';
import TabTVProgramWriting from './TabTVProgramWriting';
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function TabServices() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const [value, setValue] = React.useState(0);

    const services = [
        {
            Component: <TabBrandCopywriting />,
            Icon: <HistoryEdu />,
            Title: Text.OurServices.Services.BrandCopywriting.Title[context.language]
        },
        {
            Component: <TabMediaRelations />,
            Icon: <Diversity1 />,
            Title: Text.OurServices.Services.MediaRelations.Title[context.language]
        },
        {
            Component: <TabBrandsCooperation />,
            Icon: <Diversity3 />,
            Title: Text.OurServices.Services.BrandsCooperation.Title[context.language]
        },
        {
            Component: <TabPromotionalVideoProduction />,
            Icon: <Videocam />,
            Title: Text.OurServices.Services.PromotionalVideoProduction.Title[context.language]
        },
        {
            Component: <TabEventPlanning />,
            Icon: <TipsAndUpdates />,
            Title: Text.OurServices.Services.EventPlanning.Title[context.language]
        },
        {
            Component: <TabTVProgramWriting />,
            Icon: <LiveTv />,
            Title: Text.OurServices.Services.TVProgramWriting.Title[context.language]
        },
    ];

    // TODO: Define event
    const location = useLocation();
    const hash = location.hash;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        setValue(hash ? parseInt(hash.substring(1)) : 0);
    }, [hash]);

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <Tabs
                orientation="vertical"
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: 'divider', width:'200px' }}
                value={value}
                variant="scrollable"
            >
                {services.map((service, i) => <Tab key={i} icon={service.Icon} label={service.Title} />)}
            </Tabs>
            {
                services.map((service, i) => {
                    if (value === i) {
                        return <React.Fragment key={i}>{service.Component}</React.Fragment>
                    }
                    return false
                })
            }
        </Box>
    );
}