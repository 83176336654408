// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
    List, ListItem, ListItemIcon, ListItemText, Stack,
} from '@mui/material';

// TODO: Import MUI icon
import { RadioButtonChecked } from '@mui/icons-material';

// TODO: Import custom component
import MConceptStudioLTD from '../../Partnership/Logos/MConceptStudioLTD.webp';
import Image1 from './Image1.webp';
import Image2 from './Image2.webp';
import Image3 from './Image3.webp';
import Image4 from './Image4.webp';
import Video1 from './Video1.webp';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'MConceptStudioLTD'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={MConceptStudioLTD} sx={{ height: '50px' }} />
                {Text.CaseSharing.MConceptStudioLTD.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContentTitle>{Text.CaseSharing.MConceptStudioLTD.Videos[0].Title[context.language]}</TypographyContentTitle>
                <TypographyContents contents={Text.CaseSharing.MConceptStudioLTD.Intro[context.language]} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6} md={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image1} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image2} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image3} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image4} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                </Grid>
                <TypographyContents contents={Text.CaseSharing.MConceptStudioLTD.Videos[0].Description[context.language]} />
                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <Box
                        component="img"
                        src={Video1}
                        sx={{ borderRadius: 5, maxWidth: '900px', objectFit: 'cover', width: 1, }}
                    />
                    <DialogVideo url={Text.CaseSharing.MConceptStudioLTD.Videos[0].URL} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;