// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Typography from '@mui/material/Typography';

export default function TypographyTitle(props,) {
    // TODO: Return component
    return (
        <Typography
            color={ props.color ? props.color : 'primary'}
            sx={{
                fontWeight: 'bold',
                m: 2,
                textAlign: 'center',
            }}
            variant="h4"
        >
            {props.children}
        </Typography>
    );
}