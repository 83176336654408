// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import ContainerSecBG from '../common/ContainerSecBG.js'
import GridServices from './GridServices.js'
import TypographyTitle from '../common/TypographyTitle.js'
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function ContainerOurServices() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{ backgroundColor: 'primary.main',mt:2,pt:2 }}>
            <TypographyTitle color="white">{Text.OurServices.Title[context.language]}</TypographyTitle>
            <ContainerSecBG>
                <GridServices />
            </ContainerSecBG>
        </Box>
    );
}