// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
    List, ListItem, ListItemIcon, ListItemText, Stack,
} from '@mui/material';

// TODO: Import MUI icon
import { RadioButtonChecked } from '@mui/icons-material';

// TODO: Import custom component
import KowloonTrueLightSchool from '../../Partnership/Logos/KowloonTrueLightSchool.jpg';
import Video1 from './Video1.jpg';
import Video2 from './Video2.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const videoImage = [Video1, Video2,];

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'KowloonTrueLightSchool'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={KowloonTrueLightSchool} sx={{ height: '50px' }} />
                {Text.CaseSharing.KowloonTrueLightSchool.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContentTitle>{Text.CaseSharing.KowloonTrueLightSchool.Videos[0].Title[context.language]}</TypographyContentTitle>
                <Grid container spacing={2}>
                    <Grid
                        item
                        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                        xs={12} md={6}
                    >
                        <Stack>
                            <Box sx={{ position: 'relative' }}>
                                <Box
                                    component="img"
                                    src={videoImage[0]}
                                    sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                                />
                                <DialogVideo url={Text.CaseSharing.KowloonTrueLightSchool.Videos[0].URL} />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        sx={{ alignItems: 'start', display: 'flex', justifyContent: 'center', }}
                        xs={12} md={6}
                    >
                        <Stack>
                            <TypographyContents contents={Text.CaseSharing.KowloonTrueLightSchool.Videos[0].Description[context.language]} />
                            <List>
                                {
                                    Text.CaseSharing.KowloonTrueLightSchool.Videos[0].Services[context.language].map((duty, i) => (
                                        <ListItem key={i} sx={{ pb: 0, pt: 0 }}>
                                            <ListItemIcon sx={{ minWidth: 32 }}>
                                                <RadioButtonChecked color="primary" fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={duty} sx={{ mb: 0 }} />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <TypographyContentTitle>{Text.CaseSharing.KowloonTrueLightSchool.Videos[1].Title[context.language]}</TypographyContentTitle>
                <Grid container direction="row" spacing={2} sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Grid
                        item
                        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                        xs={12} md={6}
                    >
                        <Stack>
                            <Box sx={{ position: 'relative' }}>
                                <Box
                                    component="img"
                                    src={videoImage[1]}
                                    sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                                />
                                <DialogVideo url={Text.CaseSharing.KowloonTrueLightSchool.Videos[1].URL} />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        sx={{ alignItems: 'start', display: 'flex', justifyContent: 'center', }}
                        xs={12} md={6}
                    >
                        <Stack>
                            <TypographyContents contents={Text.CaseSharing.KowloonTrueLightSchool.Videos[1].Description[context.language]} />
                            <List>
                                {
                                    Text.CaseSharing.KowloonTrueLightSchool.Videos[1].Services[context.language].map((duty, i) => (
                                        <ListItem key={i} sx={{ pb: 0, pt: 0 }}>
                                            <ListItemIcon sx={{ minWidth: 32 }}>
                                                <RadioButtonChecked color="primary" fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={duty} sx={{ mb: 0 }} />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container direction="row-reverse" spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Grid
                        item
                        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                        xs={12} md={6}
                    >
                        <Stack>
                            <Box sx={{ position: 'relative' }}>
                                <Box
                                    component="img"
                                    src={videoImage[1]}
                                    sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                                />
                                <DialogVideo url={Text.CaseSharing.KowloonTrueLightSchool.Videos[1].URL} />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        sx={{ alignItems: 'start', display: 'flex', justifyContent: 'center', }}
                        xs={12} md={6}
                    >
                        <Stack>
                            <TypographyContents contents={Text.CaseSharing.KowloonTrueLightSchool.Videos[1].Description[context.language]} />
                            <List>
                                {
                                    Text.CaseSharing.KowloonTrueLightSchool.Videos[1].Services[context.language].map((duty, i) => (
                                        <ListItem key={i} sx={{ pb: 0, pt: 0 }}>
                                            <ListItemIcon sx={{ minWidth: 32 }}>
                                                <RadioButtonChecked color="primary" fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={duty} sx={{ mb: 0 }} />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;