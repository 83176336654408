// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Container, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import TypographyContents from '../common/TypographyContents';
import TypographySubitle from '../common/TypographySubitle';
import TypographyContentTitle from '../common/TypographyContentTitle';

import Advertorial from './MediaRelations/Advertorial.jpg'
import MediaInterview from './MediaRelations/MediaInterview.jpg'
import PressRelease from './MediaRelations/PressRelease.jpg'

import { ContextApp } from '../common/ContextApp';
import { Text } from '../common/Text';

export default function TabMediaRelations() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    const ContentImg0 = () => (
        <Box
            component="img"
            src={PressRelease}
            sx={{ objectFit: 'cover', mt: 2, width: 0.5, height: { xs: `calc(50vw * 9 / 16)`, }, }}
        />
    );

    const ContentImg1 = () => (
        <Box
            component="img"
            src={MediaInterview}
            sx={{ objectFit: 'cover', mt: 2, width: 0.5, height: { xs: `calc(40vw * 9 / 16)`, }, }}
        />
    );

    const ContentImg2 = () => (
        <Box
            component="img"
            src={Advertorial}
            sx={{ objectFit: 'cover', mt: 2, width: 0.5, height: { xs: `calc(100vw * 9 / 16)`, }, }}
        />
    );

    return (
        <Container>
            <TypographySubitle>{Text.OurServices.Services.MediaRelations.Title[context.language]}</TypographySubitle>
            <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold', mb: 2, textAlign: 'center', }} variant="h6">
                {Text.OurServices.Services.MediaRelations.Slogan[context.language]}
            </Typography>
            {
                Text.OurServices.Services.MediaRelations.Content.map((content, i) =>
                    <React.Fragment key={i}>
                        <TypographyContentTitle>
                            {content.Title[context.language]}
                        </TypographyContentTitle>
                        <TypographyContents contents={content.Content[context.language]} />
                        {i === 0 && <ContentImg0 />}
                        {i === 1 && <ContentImg1 />}
                        {i === 2 && <ContentImg2 />}
                    </React.Fragment>
                )
            }
        </Container>
    );
}