// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Stack, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import { AFGLogo, ContextApp } from './common/ContextApp.js';
import { Text } from './common/Text.js';

export default function ContainerOurServices() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{ alignItems: 'center', backgroundColor: 'primary.main', display: 'flex', flexWrap: 'wrap', gap: 2, p: 2, }}>
            <Box
                alt="Logo"
                component="img"
                src={AFGLogo[64]}
            />
            <Stack sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography color="white" variant="body2">
                        {Text.ContactUs.ContactNoTitle[context.language]}:
                    </Typography>
                    <Typography color="white" variant="body2">
                        {Text.ContactUs.ContactNo}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography color="white" sx={{whiteSpace:'nowrap'}} variant="body2">
                        {Text.ContactUs.AddressTitle[context.language]}:
                    </Typography>
                    <Typography color="white" variant="body2">
                        {Text.ContactUs.Address[context.language]}
                    </Typography>
                </Box>
            </Stack>
            <Stack>
                <Typography color="white" variant="body2">
                    {Text.Common.Copyright[0]}
                </Typography>
                <Typography color="white" variant="body2">
                    {Text.Common.Copyright[1]}
                </Typography>
            </Stack>
        </Box>
    );
}