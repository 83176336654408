// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import custom component
import ShunGranola from '../../Partnership/Logos/ShunGranola.webp';
import Image1 from './Image1.jpg';
import Image2 from './Image2.jpg';
import Image3 from './Image3.jpg';
import Image4 from './Image4.jpg';
import Video1 from './Video1.jpg';
import Video2 from './Video2.jpg';
import DialogVideo from '../../common/DialogVideo';
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';
import TypographyContents from '../../common/TypographyContents';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const videoImage = [Video1, Video2,];

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'ShunGranola'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={ShunGranola} sx={{ height: '50px' }} />
                {Text.CaseSharing.ShunGranola.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContents contents={Text.CaseSharing.ShunGranola.Intro[context.language]} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={6} sm={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image1} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={6} sm={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image2} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={6} sm={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image3} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                    <Grid item xs={6} sm={3} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={Image4} sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {
                        Text.CaseSharing.ShunGranola.Videos.map((video, i) => (
                            <Grid
                                item
                                key={i}
                                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                                xs={6}
                            >
                                <Stack key={i}>
                                    <Box sx={{ position: 'relative' }}>
                                        <Box
                                            component="img"
                                            src={videoImage[i]}
                                            sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
                                        />
                                        <DialogVideo url={video.URL} />
                                    </Box>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;