// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon
import BusinessIcon from '@mui/icons-material/Business';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

// TODO: Import custom component
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function GridInfo() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Container>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={1}>
                        <CallIcon />
                        <Typography color="primary" sx={{ fontWeight: 'bold', minWidth: '90px' }} variant="h6">
                            {Text.ContactUs.ContactNoTitle[context.language]}:
                        </Typography>
                        <Typography variant="h6">
                            {Text.ContactUs.ContactNo}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={1}>
                        <EmailIcon />
                        <Typography color="primary" sx={{ fontWeight: 'bold', minWidth: '90px' }} variant="h6">
                            {Text.ContactUs.EmailTitle[context.language]}:
                        </Typography>
                        <Typography variant="h6">
                            {Text.ContactUs.Email}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                        <BusinessIcon />
                        <Typography color="primary" sx={{ fontWeight: 'bold', minWidth:'90px' }} variant="h6">
                            {Text.ContactUs.AddressTitle[context.language]}:
                        </Typography>
                        <Typography variant="h6">
                            {Text.ContactUs.Address[context.language]}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
}