// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

import DialogVideo from '../../common/DialogVideo';
import TypographyContents from '../../common/TypographyContents';

import Orbis from '../../Partnership/Logos/Orbis.jpg';

import Video1 from './Video1.jpg';
import Video2 from './Video2.jpg';

const DialogCase = ({ openDialog, setOpenDialog }) => {
	// TODO: Define useContext
	const context = React.useContext(ContextApp);

	// TODO: Define variable
	const videoImage = [Video1, Video2,];

	// TODO: Define event
	const handleClose = () => {
		setOpenDialog(null);
	};

	// TODO: Define function

	// TODO: Return component
	return (
		<Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'Orbis'} onClose={handleClose}>
			<DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
				<Box component="img" src={Orbis} sx={{ height: '50px' }} />
				{Text.CaseSharing.Orbis.Name[context.language]}
			</DialogTitle>
			<DialogContent>
				<TypographyContents contents={Text.CaseSharing.Orbis.Intro.Content[context.language]} />
				<Grid container spacing={2}>
					{
						Text.CaseSharing.Orbis.Videos.map((video, i) => (
							<Grid
								item
								key={i}
								sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
								xs={12} md={6}
							>
								<Box sx={{ position: 'relative' }}>
									<Box
										component="img"
										src={videoImage[i]}
										sx={{ borderRadius: 5, objectFit: 'cover', width: 1, }}
									/>
									<DialogVideo url={video.URL} />
								</Box>
							</Grid>
						))
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogCase;