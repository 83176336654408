// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function TypographySubtitleH6WithIcon(props) {
    // TODO: Return component
    return (
        <Box sx={{
            alignItems: 'start',
            display: 'flex',
            mb: 1,
            mt: 2,
        }}>
            <Avatar
                color="primary"
                sx={{
                    fontSize: '1rem',
                    mr: 1,
                    width: 32,
                    height: 32,
                }}
            >
                {props.icon}
            </Avatar>
            <Typography color={props.textColor ? props.textColor : 'primary'} component="p" sx={{ fontWeight: 'bold' }} variant="h6">
                {props.text}
            </Typography>
        </Box>
    );
}