// TODO: Import React module
import * as React from 'react';

// Import material icon
/*import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FeedIcon from '@mui/icons-material/Feed';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from '@mui/icons-material/Key';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import RowingIcon from '@mui/icons-material/Rowing';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PeopleIcon from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/Report';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SchoolIcon from '@mui/icons-material/School';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import StarsIcon from '@mui/icons-material/Stars';*/

// TODO: Import custom component
import AFGLogo64 from './logo64.png'
import AFGLogo32 from './logo32.png'
import AFGLogo16 from './logo16.png'

// TODO: Return AFG logo
export const AFGLogo = {
    64: AFGLogo64,
    32: AFGLogo32,
    16: AFGLogo16,
}

// TODO: Return today date
const date = new Date();
export const Today = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}-${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}`;

// TODO: Define reducer
const globalReducer = (data, action) => {
    switch (action.type) {
        case 'dispatchLanguage': return action.value;

        case 'dispatchCurrentPage': return action.value;
        default: throw new Error();
    }
}

// TODO: Define context
export const ContextApp = React.createContext();

export const ContextAppProvider = ({ children }) => {
    // TODO: Create reducer
    const [language, dispatchLanguage] = React.useReducer(globalReducer, 'CHI');
    const [currentPage, dispatchCurrentPage] = React.useReducer(globalReducer, null);

    // TODO: Define value
    const value = {
        webserviceURL: 'https://www.pohtyh.edu.hk/~leyland/AFGHome/webservice.php',

        language: language,
        dispatchLanguage: dispatchLanguage,

        currentPage: currentPage,
        dispatchCurrentPage: dispatchCurrentPage,
    }

    return (
        <ContextApp.Provider value={value} >
            {children}
        </ContextApp.Provider >
    );
};