// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Container, Grid, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import TypographyContents from '../common/TypographyContents';
import TypographySubitle from '../common/TypographySubitle';
import TypographyContentTitle from '../common/TypographyContentTitle';

import Video_AtHomeDesign from './PromotionalVideoProduction/Video_AtHomeDesign.jpg'
import Video_Cherry from './PromotionalVideoProduction/Video_Cherry.jpg'

import { ContextApp } from '../common/ContextApp';
import { Text } from '../common/Text';

export default function TabPromotionalVideoProduction() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    const ContentGrid0 = () => (
        <Grid container spacing={1} sx={{ mt: 2, '& .MuiGrid-item': { pt: '0 !important' }, }}>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Video_AtHomeDesign}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(50vw * 9 / 16)`, }, }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    component="img"
                    src={Video_Cherry}
                    sx={{ objectFit: 'cover', mt: 2, width: 1, height: { xs: `calc(50vw * 9 / 16)`, }, }}
                />
            </Grid>
        </Grid>
    );

    return (
        <Container>
            <TypographySubitle>{Text.OurServices.Services.PromotionalVideoProduction.Title[context.language]}</TypographySubitle>
            <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold', mb: 2, textAlign: 'center', }} variant="h6">
                {Text.OurServices.Services.PromotionalVideoProduction.Slogan[context.language]}
            </Typography>
            {
                Text.OurServices.Services.PromotionalVideoProduction.Content.map((content, i) =>
                    <React.Fragment key={i}>
                        <TypographyContentTitle>
                            {content.Title[context.language]}
                        </TypographyContentTitle>
                        <TypographyContents contents={content.Content[context.language]} />
                        {i === 0 && <ContentGrid0 />}
                    </React.Fragment>
                )
            }
        </Container>
    );
}