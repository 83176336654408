// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import Typography from '@mui/material/Typography';

export default function TypographyContentTitle({ children }) {
    // TODO: Return component
    return (
        <Typography color="primary" sx={{ fontWeight: 'bold', mt: 1, }} variant="h6">
            {children}
        </Typography>
    );
}