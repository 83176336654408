// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import BoBunBeef from '../../Partnership/Logos/BoBunBeef.jpg';
import BoBunBeef_Opening from './BoBunBeef_Opening.jpg';
import BoBunBeef_Wall from './BoBunBeef_Wall.jpg';
import BoBunBeef_Promo1 from './BoBunBeef_Promo1.jpg';
import BoBunBeef_Promo2 from './BoBunBeef_Promo2.jpg';
import BoBunBeef_Promo3 from './BoBunBeef_Promo3.jpg';
//import BoBunBeef_SocialMedia1 from './BoBunBeef_SocialMedia1.jpg';
//import BoBunBeef_SocialMedia2 from './BoBunBeef_SocialMedia2.jpg';
import ImgMooncake from './ImgMooncake.jpg';

import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import TypographyContents from '../../common/TypographyContents.js';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'BoBunBeef'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={BoBunBeef} sx={{ height: '50px' }} />
                {Text.CaseSharing.BoBunBeef.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row', }, mt: 2, }}>
                    <Grid item xs={12} md={6} sx={{ /*alignItems: 'center', display: 'flex', justifyContent: 'center',*/ }}>
                        <TypographyContents contents={Text.CaseSharing.BoBunBeef.Content[context.language]} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={BoBunBeef_Wall} sx={{ borderRadius: 5, objectFit: 'cover', width: 0.8, }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2, }}>
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={BoBunBeef_Wall} sx={{ borderRadius: 5, objectFit: 'cover', width: 0.8, }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TypographyContentTitle>{Text.CaseSharing.BoBunBeef.Wall.Title[context.language]}</TypographyContentTitle>
                        <TypographyContents contents={Text.CaseSharing.BoBunBeef.Wall.Content[context.language]} />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    sx={{ flexDirection: { xs: 'column-reverse', md: 'row', }, }}
                >
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Stack>
                            <TypographyContentTitle>
                                {Text.CaseSharing.BoBunBeef.Opening.Title[context.language]}
                            </TypographyContentTitle>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: Text.CaseSharing.BoBunBeef.Opening.Content[context.language]
                                }}
                                variant="body"
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={BoBunBeef_Opening} sx={{ borderRadius: 5, objectFit: 'contain', width: 1, }} />
                    </Grid>
                </Grid>
                <TypographyContentTitle>
                    {Text.CaseSharing.BoBunBeef.Promotional.Title[context.language]}
                </TypographyContentTitle>
                <Typography>
                    {Text.CaseSharing.BoBunBeef.Promotional.Content[context.language]}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                    <Grid container spacing={2} sx={{ maxWidth: '640px' }}>
                        <Grid item xs={4}>
                            <Box
                                component="img"
                                src={BoBunBeef_Promo1}
                                sx={{ borderRadius: 5, objectFit: 'contain', width: 1, }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box
                                component="img"
                                src={BoBunBeef_Promo2}
                                sx={{ borderRadius: 5, objectFit: 'contain', width: 1, }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box
                                component="img"
                                src={BoBunBeef_Promo3}
                                sx={{ borderRadius: 5, objectFit: 'contain', width: 1, }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid
                    container
                    spacing={2}
                    sx={{ flexDirection: { xs: 'column-reverse', md: 'row', }, }}
                >
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Stack>
                            <TypographyContentTitle>
                                {Text.CaseSharing.BoBunBeef.Mooncake.Title[context.language]}
                            </TypographyContentTitle>
                            <TypographyContents contents={Text.CaseSharing.BoBunBeef.Mooncake.Content[context.language]} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Box component="img" src={ImgMooncake} sx={{ borderRadius: 5, objectFit: 'contain', width: 1, }} />
                    </Grid>
                </Grid>






                {/*
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Stack>
                                <TypographyContentTitle>
                                    {Text.CaseSharing.BoBunBeef.BrandPositioning.Title[context.language]}
                                </TypographyContentTitle>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: Text.CaseSharing.BoBunBeef.BrandPositioning.Content[context.language]
                                    }}
                                    variant="body"
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                src={BoBunBeef_Wall}
                                sx={{
                                    borderRadius: 5,
                                    objectFit: 'contain',
                                    width: 1,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TypographyContentTitle>
                        {Text.CaseSharing.BoBunBeef.SocialMedia.Title[context.language]}
                    </TypographyContentTitle>
                    <Typography>
                        {Text.CaseSharing.BoBunBeef.SocialMedia.Content[context.language]}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Grid
                            container
                            spacing={2}
                            sx={{ maxWidth: '480px' }}
                        >
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={BoBunBeef_SocialMedia1}
                                    sx={{
                                        borderRadius: 5,
                                        objectFit: 'contain',
                                        width: 1,
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={BoBunBeef_SocialMedia2}
                                    sx={{
                                        borderRadius: 5,
                                        objectFit: 'contain',
                                        width: 1,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>*/ }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;