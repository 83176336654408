// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, } from '@mui/material';

// TODO: Import MUI icon
import { } from '@mui/icons-material';

// TODO: Import custom component
import ATHomeDesign from '../../Partnership/Logos/ATHomeDesign.jpg';
import ATHome_Video1 from './ATHome_Video1.jpg';
import ATHome_Video2 from './ATHome_Video2.jpg';
import ATHome_Video3 from './ATHome_Video3.jpg';
import DialogVideo from '../../common/DialogVideo';
/*import ATHome_Intro from './ATHome_Intro.jpg';*/
import TypographyContentTitle from '../../common/TypographyContentTitle.js';
import TypographyContents from '../../common/TypographyContents';
import { ContextApp } from '../../common/ContextApp.js';
import { Text } from '../../common/Text.js';

const DialogCase = ({ openDialog, setOpenDialog }) => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const videoImage = [
        ATHome_Video1,
        ATHome_Video2,
        ATHome_Video3,
    ];

    // TODO: Define event
    const handleClose = () => {
        setOpenDialog(null);
    };

    // TODO: Return component
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={openDialog === 'ATHome'} onClose={handleClose}>
            <DialogTitle sx={{ alignItems: 'center', display: 'flex', fontWeight: 'bold', gap: 1, }}>
                <Box component="img" src={ATHomeDesign} sx={{ height: '50px' }} />
                {Text.CaseSharing.ATHome.Name[context.language]}
            </DialogTitle>
            <DialogContent>
                <TypographyContentTitle>
                    {Text.CaseSharing.ATHome.Intro.Title[context.language]}
                </TypographyContentTitle>
                <TypographyContents contents={Text.CaseSharing.ATHome.Intro.Content[context.language]} />
                <Grid container spacing={2}>
                    {
                        Text.CaseSharing.ATHome.Videos.map((video, i) => (
                            <Grid
                                item
                                key={i}
                                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }}
                                xs={12} md={4}
                            >
                                <Stack key={i}>
                                    <TypographyContentTitle>{video.Title[context.language]}</TypographyContentTitle>
                                    <Box sx={{ position: 'relative' }}>
                                        <Box
                                            component="img"
                                            src={videoImage[i]}
                                            sx={{
                                                borderRadius: 5,
                                                objectFit: 'cover',
                                                width: 1,
                                            }}
                                        />
                                        <DialogVideo url={video.URL} />
                                    </Box>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>



                {/*<Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Stack>
                            <TypographyContentTitle>
                                {Text.CaseSharing.ATHome.VideoProduction.Title[context.language]}
                            </TypographyContentTitle>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: Text.CaseSharing.ATHome.VideoProduction.Content[context.language]
                                }}
                                variant="body"
                            />
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            src={ATHome_Intro}
                            sx={{
                                borderRadius: 5,
                                objectFit: 'contain',
                                width: 1,
                            }}
                        />
                    </Grid>
                </Grid>*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{Text.Common.Close[context.language]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCase;