// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module;
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// TODO: Import MUI icon;
import { FacebookOutlined, ImportContacts, Instagram, WhatsApp, } from '@mui/icons-material';

// Import context
import { AFGLogo, ContextApp } from './common/ContextApp.js';
import { Text } from './common/Text.js';

// TODO: Import custom component

export default function MenuBar() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const drawerWidth = 256;
    const navItems = [
        {
            title: Text.Home.Title[context.language],
            navigate: '/',
        },
        {
            title: Text.AboutUs.Title[context.language],
            navigate: '/AboutUs',
        },
        {
            title: Text.OurServices.Title[context.language],
            navigate: '/OurServices',
        },
        {
            title: Text.Partnership.Title[context.language],
            navigate: '/Partnership',
        },
        {
            title: Text.CaseSharing.Title[context.language],
            navigate: '/CaseSharing',
        },
        {
            title: Text.ContactUs.Title[context.language],
            navigate: '/ContactUs',
        },
        {
            title: Text.AFGHome.Title[context.language],
            navigate: '/AFGHome',
        },
    ];

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    // TODO: Define event
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box
                alt="Logo"
                component="img"
                src={AFGLogo[32]}
                sx={{ display: { xs: 'none', md: 'inline-block' } }}
            />
            <Typography
                sx={{ my: 2 }}
                variant="subtitle2"
            >
                {Text.Common.CompanyFullName[context.language]}
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton onClick={() => { navigate(item.navigate); }} sx={{ textAlign: 'center' }}>
                            <ListItemText primary={
                                item.title === 'AFG Home' ?
                                    <Typography sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} variant="body1">
                                        <ImportContacts />
                                        {item.title}
                                    </Typography> :
                                    item.title
                            } />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText>
                            <IconButton
                                href="https://www.facebook.com/afg.creative.house"
                                target="_blank"
                            >
                                <FacebookOutlined />
                            </IconButton>
                            <IconButton
                                href="https://www.instagram.com/afg_creative_house/"
                                target="_blank"
                            >
                                <Instagram />
                            </IconButton>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    // TODO: Define function

    // TODO: Return component
    return (
        <>
            <AppBar component="nav">
                <Toolbar id="Toolbar">
                    <IconButton
                        aria-label="Menu"
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Container sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', }}>
                        <Box
                            alt="Logo"
                            component="img"
                            src={AFGLogo[64]}
                        />
                    </Container>
                    <Box
                        alt="Logo"
                        component="img"
                        src={AFGLogo[32]}
                        sx={{ display: { xs: 'none', md: 'inline-block' } }}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            flexGrow: 1,
                            ml: 1,
                        }}
                    >
                        {Text.Common.CompanyFullName['ENG']}
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <>
                            {navItems.map((item) => (
                                <Button
                                    key={item.title}
                                    onClick={() => { navigate(item.navigate); }}
                                    sx={{ color: '#fff', px: { xs: 0.5, lg: 2 } }}
                                >
                                    {
                                        item.title === 'AFG Home' ?
                                            <Typography sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} variant="body1">
                                                <ImportContacts />
                                                {item.title}
                                            </Typography> :
                                            <Typography sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} variant="body1">
                                                {item.title}
                                            </Typography>
                                    }
                                </Button>
                            ))}
                            <IconButton
                                aria-label="WhatsApp"
                                href="https://api.whatsapp.com/send?phone=85290368842"
                                target="_blank"
                                sx={{ color: 'white' }}
                            >
                                <WhatsApp />
                            </IconButton>
                            <IconButton
                                aria-label="Facebook"
                                href="https://www.facebook.com/afg.creative.house"
                                target="_blank"
                                sx={{ color: 'white' }}
                            >
                                <FacebookOutlined />
                            </IconButton>
                            <IconButton
                                aria-label="Instagram"
                                href="https://www.instagram.com/afg_creative_house/"
                                target="_blank"
                                sx={{ color: 'white' }}
                            >
                                <Instagram />
                            </IconButton>
                        </>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    ModalProps={{ keepMounted: true }}
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    variant="temporary"
                >
                    {drawer}
                </Drawer>
            </Box>
            <Toolbar id="TopAnchor" />
        </>
    );
}